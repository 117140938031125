import { Link } from "gatsby";
import React from "react";
import { CSSProperties } from "react";
import { useAudioPlayback } from "../AudioProvider";

type Props = {
  firstBackground: string;
  secondBackground: string;
  children: React.ReactNode;
  frameColor: string;
  song?: string;
  frameWidth?: number;
  frameOffest?: number;
  showSoundOnButton?: boolean;
};

const frameStyle: CSSProperties = {
  borderStyle: "solid",
  borderColor: "black",
};

const soundOnStyle: CSSProperties = {
  fontFamily: "raptor",
  fontWeight: 400,
  color: "rgba(155,155,155,1)",
  fontSize: "18px",
  textAlign: "center",
  cursor: "pointer",
};

const songNameStyle: CSSProperties = {
  color: "rgba(155,155,155,1)",
  fontFamily: "raptor",
  fontStyle: "italic",
  fontSize: "13px",
  textAlign: "center",
  textDecoration: "none",
};

const hidden: CSSProperties = {
  marginTop: 5,
  visibility: "hidden",
  opacity: 0,
  transition: "visibility 0s 1s, opacity 1s linear",
};

const visible: CSSProperties = {
  marginTop: 5,
  visibility: "visible",
  opacity: 1,
  transition: "opacity 1s linear",
};

export const Frame = (props: Props) => {
  const { unmute, stopSong, muted } = useAudioPlayback();
  const frameOffset = props.frameOffest ?? 17;
  const borderWidth = props.frameWidth ?? 10;

  const secondBackgroundNode = (
    <div
      style={{
        ...frameStyle,
        position: "absolute",
        top: -frameOffset * 2,
        bottom: frameOffset * 2,
        left: frameOffset * 2,
        right: -frameOffset * 2,
        zIndex: -2,
        backgroundColor: props.secondBackground,
        borderColor: props.frameColor,
        borderWidth,
      }}
    />
  );

  const firstBackgroundNode = (
    <div
      style={{
        ...frameStyle,
        position: "absolute",
        top: -frameOffset,
        bottom: frameOffset,
        left: frameOffset,
        right: -frameOffset,
        zIndex: -1,
        backgroundColor: props.firstBackground,
        borderColor: props.frameColor,
        borderWidth,
      }}
    />
  );

  const contentNode = (
    <div
      style={{
        ...frameStyle,
        backgroundColor: "black",
        borderColor: props.frameColor,
        borderWidth,
        overflow: "hidden",
      }}
    >
      {props.children}
    </div>
  );

  const toggleMuteSong = () => {
    if (muted) {
      unmute();
    } else {
      stopSong();
    }
  };

  return (
    <div
      style={{
        paddingRight: frameOffset * 2,
        paddingTop: frameOffset * 2,
      }}
    >
      <div
        style={{
          position: "relative",
        }}
        onClick={() => {
          if (props.showSoundOnButton) toggleMuteSong();
        }}
      >
        {secondBackgroundNode}
        {firstBackgroundNode}
        {contentNode}
      </div>
      {props.showSoundOnButton && (
        <div style={{ textAlign: "center", marginTop: 15 }}>
          <div style={soundOnStyle} onClick={toggleMuteSong}>
            {muted ? "turn sound on" : "turn sound off"}
          </div>
          <div style={muted ? hidden : visible}>
            <div style={soundOnStyle}>now playing:</div>
            <a
              href="https://www.instagram.com/_frank_hayes_/"
              target="_blank"
              style={songNameStyle}
            >
              thank you by frank hayes
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
