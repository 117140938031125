// extracted by mini-css-extract-plugin
export var blogContent = "Typography-module--blogContent--MKWao";
export var blogHeading = "Typography-module--blogHeading--L5TRs";
export var debutEventDetails = "Typography-module--debutEventDetails--fWInw";
export var debutItalicText = "Typography-module--debutItalicText--Ifcsi";
export var debutRaptorH1 = "Typography-module--debutRaptorH1--2HJwp";
export var frameRowSubtitle = "Typography-module--frameRowSubtitle--IYD-4";
export var frameRowTitle = "Typography-module--frameRowTitle--kMDtI";
export var h1 = "Typography-module--h1--b0klt";
export var h2 = "Typography-module--h2--iOrlB";
export var h4 = "Typography-module--h4--heOEj";
export var h5 = "Typography-module--h5--oovEX";