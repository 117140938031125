import * as React from "react";
import * as styles from "./Video.module.css";

interface Props {
  src: string;
  muted: boolean;
  autoPlay: boolean;
  loop: boolean;
  playsInline: boolean;
  style?: any;
}

export const Video = ({
  src,
  muted,
  autoPlay,
  loop,
  playsInline,
  style,
}: Props) => {
  return (
    <video
      src={src}
      style={style}
      className={styles.video}
      muted={muted}
      autoPlay={autoPlay}
      loop={loop}
      playsInline={playsInline}
    />
  );
};
