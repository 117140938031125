import React from "react";
import { useLocation } from "@reach/router";

const ContentStyle = {
  fontSize: "12px",
  fontFamily: "editorial",
  textAlign: "center",
};

const Footer = () => {
  const location = useLocation();
  const isHome = location.pathname === "/";
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 50,
        marginBottom: 20,
        width: "100%",
        color: "red",
      }}
    >
      <p style={ContentStyle}>
        {isHome
          ? `“1. Studio of America, Inc.” (informally known as Studio), is a creative collective handmaking art through software—yesterday, today, and forever.`
          : "made with love in the City of New York."}
      </p>
    </div>
  );
};

export default Footer;
