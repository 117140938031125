import * as React from "react";

type Props = {
  children: React.ReactNode;
};

export const Container = (props: Props) => {
  return (
    <div
      style={{
        maxWidth: 240,
        width: "100%",
      }}
    >
      {props.children}
    </div>
  );
};
