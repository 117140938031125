import * as React from "react";
import * as styles from "./Image.module.css";

interface Props {
  src: string;
  alt: string;
  style?: any;
}

export const Image = ({ src, alt, style }: Props) => {
  return <img src={src} alt={alt} className={styles.image} style={style} />;
};
