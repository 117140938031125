import * as React from "react";
import * as styles from "./Typography.module.css";
import { CSSProperties } from "react";

type Props = {
  children: React.ReactNode;
  style?: CSSProperties;
  onClick?: () => void;
};

export const Heading1 = (props: Props) => {
  return <h1 className={styles.h1} {...props} />;
};

export const Heading2 = (props: Props) => {
  return <h2 className={styles.h2} {...props} />;
};

export const BeliefsHeading = (props: Props) => {
  return <h4 className={styles.h4} {...props} />;
};

export const BeliefsContent = (props: Props) => {
  return (
    <h5 className={styles.h5} {...props}>
      {props.children}
    </h5>
  );
};

export const BlogHeading = (props: Props) => {
  return (
    <h5 className={styles.blogHeading} {...props}>
      {props.children}
    </h5>
  );
};

export const BlogContent = (props: Props) => {
  return (
    <h5 className={styles.blogContent} {...props}>
      {props.children}
    </h5>
  );
};

export const FrameRowTitle = (props: Props) => {
  return (
    <h5 className={styles.frameRowTitle} {...props}>
      {props.children}
    </h5>
  );
};

export const FrameRowSubtitle = (props: Props) => {
  return (
    <h5 className={styles.frameRowSubtitle} {...props}>
      {props.children}
    </h5>
  );
};

export const DebutPartyDetails = (props: Props) => {
  return <div>{props.children}</div>;
};

export const DebutRaptorHeading1 = (props: Props) => {
  return <h1 className={styles.debutRaptorH1} {...props} />;
};

export const DebutEventDetails = (props: Props) => {
  return <h5 className={styles.debutEventDetails} {...props} />;
};

export const DebutItalicText = (props: Props) => {
  return <h5 className={styles.debutItalicText} {...props} />;
};
