import React from "react";
import { Link } from "gatsby";
import { BeliefsHeading } from "../Typography/Typography";
import { useLocation } from "@reach/router";
import * as styles from "./NavigationHeader.module.css";

// TODO: add in hand-drawn circle around active page
const NavigationHeader = () => {
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <nav
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        marginTop: 40,
        overflowX: "hidden",
        width: "100%",
      }}
    >
      <Link to="/" activeClassName="active" className={styles.linkContainer}>
        <BeliefsHeading>home</BeliefsHeading>
        {pathname === "/" && (
          <img src="/handwritten-circle.svg" className={styles.circle} />
        )}
      </Link>
      <Link
        to="/beliefs"
        activeClassName="active"
        className={styles.linkContainer}
      >
        <BeliefsHeading>beliefs</BeliefsHeading>
        {pathname.includes("/beliefs") && (
          <img src="/handwritten-circle.svg" className={styles.circle} />
        )}
      </Link>
      <Link
        to="/team"
        activeClassName="active"
        className={styles.linkContainer}
      >
        <BeliefsHeading>team</BeliefsHeading>
        {pathname.includes("/team") && (
          <img src="/handwritten-circle.svg" className={styles.circle} />
        )}
      </Link>
    </nav>
  );
};

export default NavigationHeader;
